<template>
    <div id="successPage">
      <div class="header">
        <img class="bg" :src="personInfo.photoUrl" draggable="false">
      </div>
      <div class="container">
        <template v-if="this.type==='1'">
          <div class="section">
            <div class="section-title">
              <div class="title">
              </div>
              <div class="tip">会員ランクを変更しました</div>
              <div class="tip">{{ Date.now() | formatDate('yyyy-MM-dd')  }}から{{ personInfo.gradesName }}になりました！</div>
            </div>
            <div class="section-bottom">
              <router-link :to="{name: 'MemberIndex'}" replace type="button" tag="button" class="btn">他の会員ランクを確認</router-link>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="section">
            <div class="section-title">
              <div class="title">
              </div>
              <div class="tip">会員ランクを変更しました</div>
              <div class="tip">{{ getTime }}に自動で{{ user.gradesName }}に変更</div>
              <!-- <div class="tip">{{ personInfo.gradesName }}</div> -->
            </div>
            <div class="section-bottom">
              <router-link :to="{name: 'MemberIndex'}" replace type="button" tag="button" class="btn">他の会員ランクを確認</router-link>
            </div>
          </div>
        </template>
      </div>
      <div class="footer">
        <router-link :to="{name: 'Home'}" type="button" tag="button" class="submit-btn">
          <span>ホームへ</span>
        </router-link>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'MemberSuccess',
  components: {
  },
  data () {
    return {
      user: {},
      type: ''
    };
  },
  created () {
  },
  mounted () {
    const { $route: { query: { user, type } } } = this;
    this.user = user || {};
    this.type = type;
  },
  computed: {
    ...mapGetters(['personInfo']),

    /* 将时间进行处理  格式  xxxx年xx月xx日 */
    getTime () {
      let { $utils, user } = this;
      return $utils.formatDate(user.createTime, 'yyyy年MM月dd日');
    }
  },
  watch: {},
  methods: {}
};
</script>

<style scoped lang="less">
  #successPage {
    .header {
      background-color: #232324;

      .bg {
        width: 100%;
      }
    }

    .container {
      padding: 78px 70px 0;
      text-align: center;

      .section-title {
        margin-bottom: 92px;

        .title {
          font-size: 36px;
          font-weight: bold;
          line-height: 46px;
        }

        .tip {
          font-size: 24px;
          line-height: 32px;
          opacity: 0.7;

          &:not(:first-child) {
            margin-top: 26px;
          }
        }
      }

      .section-bottom {
        font-size: 24px;
        line-height: 32px;
        color: @primaryBlueColor;
        text-decoration: underline;
        opacity: 0.7;
      }
    }

    .footer {
      padding-right: 28px;
      padding-left: 28px;
      margin-top: 220px;
    }
  }
</style>